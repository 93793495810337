<template>
  <div>
    <eden-page-header
      :title="'Customers'"
      :subtitle="`${loading ? '-' : customer_name}`"
      section="All Gardener's Log"
    />

    <div>
      <eden-table-actions title="Gardener’s Log" :show-search="false" />
      <template>
        <p v-if="loading">Loading</p>
        <template v-else>
          <el-table :data="gardener_logs" empty-text="No Gardeners">
            <el-table-column>
              <template slot="header">
                <div class="table--header">
                  <span>Gardener’s Name</span>
                  <i class="el-icon-bottom"></i>
                </div>
              </template>
              <template v-slot="scope">
                <span class="font-sm">
                  {{ scope.row.gardener_name }}
                </span>
              </template>
            </el-table-column>

            <el-table-column min-width="200">
              <template slot="header">
                <div class="table--header">
                  <span> Switch Date </span>
                  <i class="el-icon-bottom"></i>
                </div>
              </template>
              <template slot-scope="scope">
                <span
                  v-if="scope.row.changed_at === '-'"
                  class="font-sm mt-10 mb-10"
                >
                  Present
                </span>
                <span v-else class="font-sm mt-10 mb-10">
                  <span>
                    {{ formatDateAndTime(scope.row.assigned_at, "do m, y") }} -
                  </span>

                  <span>
                    {{ formatDateAndTime(scope.row.changed_at, "do m, y") }}
                  </span>
                </span>
                <span class="font-sm text-grey-tertiary">
                  Changed by
                  <span class="text-primary text-bold">
                    {{ formatName(scope.row.assignment_made_by) }}
                  </span>
                </span>
              </template>
            </el-table-column>
            <el-table-column>
              <template slot="header">
                <div class="table--header">
                  <span>Action</span>
                  <i class="el-icon-bottom"></i>
                </div>
              </template>
              <template v-slot="scope">
                <span v-if="scope.row.changed_at !== '-'" class="font-sm">
                  <el-button type="info" plain @click="viewChat(scope.$index)">
                    View Chat
                  </el-button>
                </span>
              </template>
            </el-table-column>
          </el-table>
          <customer-gardener-chat :show.sync="showChat" :log="gardener_log" />
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import customer from "@/requests/customers/customer/index";
import CustomerGardenerChat from "@/components/Customers/Individual/Gardener/Chat/CustomerGardenerChat.vue";
export default {
  name: "CustomerGardeners",
  components: { CustomerGardenerChat },

  data() {
    return {
      loading: false,
      gardener_logs: [],
      gardener_log: {},
      customer_name: null,
      showChat: false,
    };
  },
  created() {
    this.getGardenerLogs();
  },
  computed: {
    customerId() {
      return parseInt(this.$route.params.id);
    },
  },
  methods: {
    getGardenerLogs() {
      this.loading = true;
      const id = this.customerId;
      customer
        .gardenersLog(id)
        .then((response) => {
          if (response.data.status) {
            this.customer_name = this.formatToTitleCase(
              response.data.customer_name,
            );
            this.gardener_logs = this.formatGardenerLog(response.data.data);
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    viewChat(index) {
      this.gardener_log = this.gardener_logs[index];
      this.showChat = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-button--info.is-plain {
  background: #f0f4f2;
  color: #0f241b;
  border-radius: 0;
  border: 0;
  margin-bottom: 25px;
}
</style>
