<template>
  <el-dialog :visible.sync="shouldShow" @close="closeEvent" width="30%">
    <template slot="title">
      <div class="title">
        <p class="font-base mb-10">
          <span class="text-bold">Gardener In Charge: </span>
          <span>{{ log.gardener_name }}</span>
        </p>
        <p class="font-sm mb-10">
          <span class="text-bold"> Date: </span>
          <span
            >{{ formatDateAndTime(log.assigned_at, "do m, y") }} -
            {{ formatDateAndTime(log.changed_at, "do m, y") }}</span
          >
        </p>
      </div>
    </template>
    <div v-loading="fetching" class="chats">
      <chat-message
        v-if="messages.length"
        :messages="messages"
        :gardener="log.gardener_name"
      />
      <div v-else class="empty is-flex justify-center">
        <p class="font-base">No messages</p>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import individual from "@/requests/customers/individual";
import ChatMessage from "@/components/Customers/Individual/Gardener/Chat/ChatMessage";
export default {
  name: "CustomerGardenerChat",
  components: { ChatMessage },

  props: {
    show: {
      type: Boolean,
      required: true,
    },
    log: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      fetching: false,
      messages: [],
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    show() {
      this.show ? this.getGardenerChat() : (this.messages = []);
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
    },

    getGardenerChat() {
      this.fetching = true;
      const { user_id, gardener_id, changed_at } = this.log;

      individual
        .gardenerChat(
          user_id,
          gardener_id,
          this.formatDate(changed_at, "y-m-d"),
        )
        .then((response) => {
          if (response.data.status) {
            const { data } = response.data;
            if (data.length) {
              this.messages = data[0].chat_history.messages;
            }
            this.fetching = false;
          }
        })
        .catch((error) => {
          this.fetching = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>
