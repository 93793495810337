<template>
  <div>hello</div>
</template>

<script>
export default {
  name: "ChatMessage",
  props: {
    messages: {
      type: Array,
      default() {
        return [];
      },
    },
    gardener: {
      type: String,
      default: "",
    },
    data() {
      return {
        chatDates: [],
        checkImgSrc: "",
        previewImageSrc: "",
        retryLoading: false,
      };
    },
    methods: {
      getUser(gardener) {
        return this.gardener.toLowerCase() === gardener.toLowerCase()
          ? "gardener"
          : "customer";
      },
      viewImage() {
        //
      },
      formatMessage(message) {
        let urlRegex = /(https?:\/\/[^\s]+)/g;
        return message.replace(urlRegex, (url) => {
          return `<a href="${url}" target="_blank"  style="color:#03a84e;text-decoration:underline;">${url}</a>`;
        });
      },
    },
  },
  computed: {
    groupedMessages() {
      let groupedMessages = {};
      this.messages.forEach((message) => {
        const date = this.formatDate(message.created_at, "y-m-d");
        if (!groupedMessages[date]) {
          groupedMessages[date] = [];
        }
        groupedMessages[date].push(message);
      });
      return groupedMessages;
    },
  },
};
</script>

<style lang="scss" scoped>
.chats {
  width: 60%;
  padding: 10px;
  text-align: left;
  margin-bottom: 0.325rem;
  color: #0f241b;
  @media only screen and (max-device-height: 667px) {
    width: 80%;
  }

  &.gardener {
    background-color: #f0f4f2;
    border-radius: 16px 16px 16px 2px;
    margin-right: auto;

    & + p {
      text-align: left;
    }
  }

  &.customer {
    border: 1px solid #6ece8a;
    border-radius: 16px 16px 2px 16px;
    margin-left: auto;
    background-color: #ffffff;

    & + p {
      text-align: right;
    }
  }

  &__box {
    font-size: 14px;
  }

  &__time {
    img {
      margin-left: 5px;
      height: 14px;
      width: auto;
    }
  }
}
p {
  margin-bottom: 1.5rem;

  &.capitalize {
    text-align: center;
  }

  &.message {
    margin-bottom: 0;
    white-space: pre-line;
    color: #0f241b;

    img {
      height: 150px;
      width: auto;
    }
  }
}
</style>
